import { mutate } from "swr";
import { Dispatch, SetStateAction } from "react";
import { IReferral } from "../components/primaryrender/refer/types";
import { IContact } from "../components/primaryrender/contacts/Contact/types";
import { NavigationFrame } from "./AppContextProvider/AppContextProvider";
import { GetUserGalleriesResponse } from "../hooks/getUserGalleries";
import { Showcase } from "../components/ProjectShowcases/types";

export interface IBusinessHourProps {
  from: string;
  to: string;
  day: string;
  custom?: string | null;
}

export interface faq {
  landing_page: number;
  id: number | string;
  question: string;
  answer: string;
  order: number;
}

interface IGoogleServiceAccount {
  gbp_client_id: string;
}

export interface ITeammate {
  associated_customer_pk?: number | null;
  email: string;
  first_name: string;
  id: number;
  invite_status?: string;
  landing_page_id?: number;
  last_name: string;
  name: string;
  phone: string;
  primary_owner_id?: number;
  type?: string;
}

export interface Service {
  service_id?: number;
  "service-name": string;
  photos: ServicePhoto[];
  "service-description": string;
  "service-description-long": string;
  primary_keyword: string;
  keywords: string[];
  include_in_socials: boolean;
  order: number;
  share_start_date: string | null;
  share_end_date: string | null;
}

export interface CoverPhoto {
  alt_tag: any;
  caption: any;
  hero_gallery_photo_pk: number;
  order: number;
  photo: string;
}

export interface ServicePhoto {
  id?: number;
  order: number;
  photo: string;
  stock: boolean;
}

// The following interface represents the photo object structure that we use on the /site page.
export interface FormattedGalleryPhoto {
  id: number;
  photo: string;
  data: string;
  url: string;
  gallery_name: string;
  all_photos_order: number;
  gallery_order: number;
  homepage_order: number;
  shared_on_social: boolean;
  created_at: string;
  source: string;
  gpt_description: string | null;
  is_good_for_post: boolean | null;
  before_after: boolean;
  before_after_id: number | null;
  before_after_photo_1: string | null;
  before_after_photo_2: string | null;
  before_after_layout: string | null;
  before_after_text: boolean | null;
  before_after_logo: boolean | null;
  before_after_qr: boolean | null;
  newly_pulled?: "facebook" | "google" | "instagram" | "";
  selected?: boolean;
}

// The following interface represents the photo object structure that we receive from the api.
export interface GalleryPhoto {
  all_photos_order: number;
  alt_tag: string | null;
  associated_service: string | null;
  created_at: string;
  gallery_name: string;
  gallery_order: number;
  gpt_description: string | null;
  homepage_order: number;
  photo: string;
  photo_pk: number;
  shared_on_social: boolean;
  source: string;
  is_good_for_post: boolean | null;
  before_after?: boolean;
  before_after_id?: number;
  before_after_layout?: string;
  before_after_logo?: boolean;
  before_after_photo_1?: string;
  before_after_photo_2?: string;
  before_after_qr?: boolean;
  before_after_text?: boolean;
}

export interface Gallery {
  "gallery-name": string;
  "gallery-subtitle": string;
  id?: number;
}

export interface BasicInfoProps {
  logo: string;
  faqs: faq[];
  address: string;
  street_address: string;
  country: string;
  city: string;
  state: string;
  zip_code: string;
  email: string;
  phone: string;
  business_hours: IBusinessHourProps[];
  company_tagline: string;
  about: string;
  about_upload: string;
  hero_gallery_photos: any[];
  sitePk: any;
  auto_add_five_star_reviews?: boolean;
  toggle_auto_adds_on_connect: boolean;
  toggle_auto_adds_on_connect_reviews: boolean;
  auto_add_fb_reviews: boolean;
  auto_add_gmb_reviews: boolean;
  auto_add_fb_photos: boolean;
  auto_add_instagram_photos: boolean;
  auto_add_gmb_photos: boolean;
  default_upload_gallery: string;
  services: Service[];
  service_areas: any[];
  gmb_photos_toggle: boolean;
  gmb_business_info_toggle: boolean;
  gmb_posts_toggle: boolean;
  gmb_services_toggle: boolean;
  fb_photos_toggle: boolean;
  fb_business_info_toggle: boolean;
  fb_posts_toggle: boolean;
  ig_photos_toggle: boolean;
  ig_posts_toggle: boolean;
  default_review_link: string;
  facebook: string;
  custom_emails: any[];
  review_link: string;
  review_link_more: string;
  leave_review_link_3: string;
  yelp_review_link: string;
  gmb_location_verification_status: string;
  gmb_refresh_token: string;
  gmb_access_token: string;
  gmb_token_expiration: any;
  google_contacts_refresh_token: string;
  google_contacts_access_token: string;
  google_contacts_token_expiration: any;
  google_service_account: IGoogleServiceAccount;
  gmb_location_id: any;
  gmb_account_id: any;
  fb_user_access_token: string;
  fb_page_access_token: string;
  fb_page_id: string;
  fb_tokens_expired: boolean;
  custom_response_to_homeowner: string;
  industry: string;
  custom_primary: string;
  custom_secondary: string;
  quickbooks_refresh_token: string;
  quickbooks_expiration_date: string;
  does_use_quickbooks: any;
  is_social_footer_active: boolean;
  social_post_footer: string;
  auto_send_response_to_homeowner: boolean;
  bookings_reminders_period: string;
  google_ads_account_id: string;
  twilio_website_phone: string;
  website_call_forwarding_on: boolean;
  twilio_gbp_phone: string;
  gbp_call_forwarding_on: boolean;
  version: string;
  title_tag: string;
  meta_description: string;
  last_login_time: any;
  second_last_login_time: string | null;
  company_type: string;
  gmb_pending_at: string;
  review_links: IReviewLink[];
  default_request_review_text: string;
  teammates: any[];
  custom_layout: TemplateBlock;
  ads_payment_failure: boolean;
  showing_tooltip_count: number;
  gbp_call_bookings_reminders_on: boolean;
  website_call_bookings_reminders_on: boolean;
  website_text_bookings_reminders_on: boolean;
  add_related_showcase_to_homeowner_response: boolean;
  appointment_scheduling: boolean;
  nylas_grant_id: string;
  nylas_auth_token: string;
}

type TemplateBlock = {
  [key: string]: {
    version: "v2" | "v2.1" | "v2.2" | "v2.3" | "none";
    order: number;
  };
};

export interface IReviewLink {
  title: string;
  link: string;
  icon: string;
}

export interface PlanType {
  id: string;
  type: string;
  billing_cycle: string;
  price: string;
  amount: number;
  quantity: number;
  current_period_start: string;
  current_period_end: string;
}

export interface Review {
  id: string;
  date_posted: string;
  source: string;
  review_content: string;
  name: string;
  rating: number;
  status: string;
  review_id_from_google?: any;
  testimonial_reply_comment?: any;
  testimonial_reply_updated_time?: any;
  rating_id_from_facebook: any;
  testimonial_facebook_reply_comment: any;
  testimonial_facebook_reply_updated_time: any;
  testimonial_facebook_latest_reply_id: any;
  social_post_footer: string;
  is_social_footer_active: boolean;
  related_project_showcases: Array<Showcase>;
  testimonial_service: string;
  testimonial_giver_title: string;
}

export interface PaymentMethod {
  id: string;
  type: string;
  number: string;
  selected: boolean;
  expiration?: string;
  created?: string;
}

export type ProfileApiResponse = {
  first_name: string;
  last_name: string;
  stripe_created_at: string;
  email: string;
  phone: string;
  customer_id: string;
  slug: string;
  domain: string;
  company_name: string;
  review_link: string;
  customer_pk: number;
  landing_page_pk: number;
  contract_id: number;
  fb_user_access_token: string;
  fb_page_access_token: string;
  fb_page_id: string;
  is_instagram_connected: boolean;
  instagram_user_access_token: string;
  instagram_page_access_token: string;
  instagram_user_id: string;
  landing_page_creation_date: string;
  gmb_token_expiration: string;
  gmb_refresh_token: string;
  gmb_location_verification_status: string;
  gmb_location_id: string;
  gmb_account_id: string;
  gmb_email: string;
  first_login_after_publish: boolean;
  total_login_count: number;
  sent_email_zapier: boolean;
  stripe_connect_account_id: string;
  stripe_connect_payment_link: string;
  who_pays_stripe_fees: string;
  stripe_due_date: string;
  stripe_payment_link_on_site: boolean;
  stripe_auto_follow_up_7_days: boolean;
  payments_flag: boolean;
  blogs_flag: boolean;
  stripe_incentive_triggered: boolean;
  default_tax_on: boolean;
  default_tax_type: string;
  default_tax_custom_type: string;
  default_tax_rate: string;
  user_type: string;
  auth0_user_id: string;
  download_app_popup_shown: boolean;
  digital_media_popup_shown: boolean;
  physical_marketing_popup_shown: boolean;
  dashboard_connections_screen_shown: boolean;
  quickbooks_expiration_date: string;
  quickbooks_refresh_token: string;
  does_use_quickbooks: boolean;
  review_monthly_goal: any;
  first_login_time: string;
  show_facebook_popup_for_live_site_announcement: boolean;
  google_ads_account_id: string;
  referral_id: string;
  nylas_grant_id: string;
  nylas_configuration_ids: string[];
  ai_assistant_notifications_on: boolean;
};

export interface ProfileLandingPage {
  slug: string;
  landing_page_pk: number;
  company_name: string;
  domain: string;
}

export interface EmailPlan {
  id: string;
  type: "email";
  billing_cycle: string;
  price: string;
  amount: number;
  quantity: number;
}

export interface AdsPlan {
  id: string;
  type: "ads";
  billing_cycle: "monthly";
  price: string;
  amount: number;
  quantity: number;
}

interface SubscriptionSchedule {
  id: string;
  object: string;
  application: string | null;
  canceled_at: number | null;
  completed_at: number | null;
  created: number;
  current_phase: {
    end_date: number;
    start_date: number;
  };
  customer: string;
  default_settings: {
    application_fee_percent: number | null;
    automatic_tax: {
      enabled: boolean;
      liability: string | null;
    };
    billing_cycle_anchor: string;
    billing_thresholds: any | null;
    collection_method: string;
    default_payment_method: string | null;
    default_source: string | null;
    description: string | null;
    invoice_settings: {
      account_tax_ids: any | null;
      days_until_due: number | null;
      issuer: {
        type: string;
      };
    };
    on_behalf_of: string | null;
    transfer_data: any | null;
  };
  end_behavior: string;
  livemode: boolean;
  metadata: Record<string, unknown>;
  phases: {
    add_invoice_items: any[];
    application_fee_percent: number | null;
    billing_cycle_anchor: any | null;
    billing_thresholds: any | null;
    collection_method: string | null;
    coupon: string | null;
    currency: string;
    default_payment_method: string | null;
    default_tax_rates: any[];
    description: string | null;
    discounts: {
      coupon: string;
      discount: any | null;
      promotion_code: string | null;
    }[];
    end_date: number;
    invoice_settings: any | null;
    metadata: Record<string, unknown>;
    on_behalf_of: string | null;
    plans: {
      billing_thresholds: any | null;
      discounts: any[];
      metadata: Record<string, unknown>;
      plan: string;
      price: string;
      quantity: number;
      tax_rates: any[];
    }[];
    prorate: boolean;
    proration_behavior: string;
    start_date: number;
    tax_percent: number | null;
    transfer_data: any | null;
    trial_end: number | null;
  }[];
  released_at: number | null;
  released_subscription: string | null;
  renewal_interval: string | null;
  status: string;
  subscription: string;
  test_clock: string | null;
}

export interface ProfileInfo extends ProfileApiResponse {
  personal_phone: string;
  current_plan: PlanType;
  stripe_created_at: string;
  email_plan: EmailPlan | null;
  ads_plan: AdsPlan | null;
  trialing: boolean;
  subscription_schedule: SubscriptionSchedule | null;
  coupons: any;
  payment_methods: Array<PaymentMethod>;
  stripe_customer_id: string;
  stripe_subscription: any;
  main_subscription_id: any;
  ads_stripe_subscription_id: any;
  stripe_plans_in_price_tier: any;
  default_payment_method: string;
  is_delinquent: boolean;
  first_login_after_publish: boolean;
  sent_initial_zapier: boolean;
  stripe_connect_account_id: string;
  stripe_connect_payment_link: string;
  who_pays_stripe_fees: string;
  stripe_due_date: string;
  stripe_payment_link_on_site: boolean;
  stripe_auto_follow_up_7_days: boolean;
  payments_flag: boolean;
  campaigns_flag: boolean;
  blogs_flag: boolean;
  total_login_count: number;
  stripe_incentive_triggered: boolean;
  default_tax_on: boolean;
  default_tax_type: string;
  default_tax_custom_type: string;
  default_tax_rate: string;
  user_type: string;
  download_app_popup_shown: boolean;
  digital_media_popup_shown: boolean;
  physical_marketing_popup_shown: boolean;
  dashboard_connections_screen_shown: boolean;
  review_monthly_goal: any;
  subscription_status: string;
  invoice_credit_balance: number;
  is_trial_eligible: boolean;
  latestCanceledPlan: any;
  is_test_price: boolean;
  landing_pages: ProfileLandingPage[];
  nylas_grant_id: string;
  nylas_configuration_ids: string[];
  active_subscriptions: Array<{
    id: string;
    status: string;
    [key: string]: any;
  }>;
}

export interface CalendarEvent {
  id: string;
  start_time: number;
  end_time: number;
  title: string;
}

export interface DesktopContextType {
  handleDesktopMenuOpen: (isOpen: boolean) => void;
  desktopMenuOpen: boolean;
  handleSelectedFrame: (name: string) => void;
  selectedFrame: Array<NavigationFrame>;
  profileInfo: ProfileInfo;
  setProfileInfo: (profile_info: ProfileInfo) => void;
  reviewInfo: Array<Review>;
  setReviewInfo: (reviews: Array<Review>) => void;
  isGmbAuthenticated: boolean;
  isGmbAuthenticationLoading: boolean;
  setIsGmbAuthenticated: Dispatch<SetStateAction<boolean>>;
  gmbVerificationStatus: string;
  setGmbVerificationStatus: Dispatch<SetStateAction<string>>;
  isFacebookAuthenticated: boolean;
  setIsFacebookAuthenticated: Dispatch<SetStateAction<boolean>>;
  isInstagramAuthenticated: boolean;
  setIsInstagramAuthenticated: Dispatch<SetStateAction<boolean>>;
  basicInfo: BasicInfoProps;
  setBasicInfo: (basicInfoProps: BasicInfoProps) => void;
  bottomLeftNavigation: Array<NavigationFrame>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  isLoginVerified: boolean;
  setIsLoginVerified: Dispatch<SetStateAction<boolean>>;
  isSessionVerified: boolean;
  setIsSessionVerified: Dispatch<SetStateAction<boolean>>;
  checkAuth: () => void;
  setHelpModal: (isOpen: boolean) => void;
  user: boolean;
  contacts: IContact[];
  setContacts: any;
  setUser: (user: boolean) => void;
  rightDrawerOpen: any;
  setRightDrawerOpen: any;
  paymentFailureError: boolean;
  photoUploadedSuccessfully: boolean;
  setPhotoUploadedSuccessfully: (photoUploadedSuccessfully: boolean) => void;
  facebookUserToken: string;
  setFacebookUserToken: (facebookUserToken: string) => void;
  facebookPageToken: string;
  setFacebookPageToken: (facebookPageToken: string) => void;
  facebookPageId: string;
  setFacebookPageId: (facebookPageId: string) => void;
  gmbAccessToken: string;
  setGmbAccessToken: (arg0: string) => void;
  gmbRefreshToken: string;
  setGmbRefreshToken: (arg0: string) => void;
  gmbLocationId: string;
  setGmbLocationId: (arg0: string) => void;
  gmbAccountId: string;
  setGmbAccountId: (arg0: string) => void;
  gmbEmail: string;
  setGmbEmail: (arg0: string) => void;
  facebookPageName: string;
  setFacebookPageName: (arg0: string) => void;
  landingPageCreationDate: string;
  initialApiLoadingState: InitialApiLoadingState;
  autoSendResponseOn: boolean;
  setAutoSendResponseOn: any;
  paymentsFlagLocalStorage: string;
  campaignsFlagLocalStorage: string;
  campaigns: any[];
  setCampaigns: Dispatch<SetStateAction<any[]>>;
  teammates: ITeammate[];
  setTeammates: Dispatch<SetStateAction<ITeammate[]>>;
  isQuickbooksAuthenticated: boolean;
  setIsQuickbooksAuthenticated: (arg0: boolean) => void;
  doesUseQuickbooks: string;
  setDoesUseQuickbooks: (arg0: string) => void;
  userStripeAccount: any;
  setUserStripeAccount: Dispatch<SetStateAction<any>>;
  allPayouts: any;
  setAllPayouts: Dispatch<SetStateAction<any>>;
  allUserStripeInvoices: any;
  setAllUserStripeInvoices: Dispatch<SetStateAction<any>>;
  userFacebookPosts: any;
  setUserFacebookPosts: Dispatch<SetStateAction<any>>;
  userFacebookGroups: any;
  setUserFacebookGroups: Dispatch<SetStateAction<any>>;
  userInstagramPosts: any;
  setUserInstagramPosts: Dispatch<SetStateAction<any>>;
  userGMBPosts: any;
  setUserGMBPosts: Dispatch<SetStateAction<any>>;
  userSchedulePosts: any;
  setUserSchedulePosts: Dispatch<SetStateAction<any>>;
  userPersonalBlogs: any;
  setUserPersonalBlogs: Dispatch<SetStateAction<any>>;
  userScheduleBlogs: any;
  setUserScheduleBlogs: Dispatch<SetStateAction<any>>;
  userGalleries?: GetUserGalleriesResponse;
  setUserGalleries: Dispatch<SetStateAction<any>>;
  usePersonalBlogInfoLoading: boolean;
  useScheduleBlogInfoLoading: boolean;
  userPhoto?: GalleryPhoto[];
  notiPreferencesFromCourierData: any;
  notiPreferencesFromCourierLoading: boolean;
  notiPreferencesFromCourierDataForBookings: any;
  notiPreferencesFromCourierLoadingForBookings: boolean;
  userAllQuotes: any;
  setUserAllQuotes: Dispatch<SetStateAction<any>>;
  allTasksResponse: any;
  setAllTasksResponse: Dispatch<SetStateAction<any>>;
  facebookSocialPhotos: any;
  setFacebookSocialPhotos: Dispatch<SetStateAction<any>>;
  googleSocialPhotos: any;
  setGoogleSocialPhotos: Dispatch<SetStateAction<any>>;
  userInvoicesInDB: any;
  setUserInvoicesInDB: Dispatch<SetStateAction<any>>;
  userQuotesInDB: any;
  setUserQuotesInDB: Dispatch<SetStateAction<any>>;
  allReviews: any;
  allReviewsLoading: boolean;
  allRequests: any;
  setAllRequests: Dispatch<SetStateAction<any>>;
  userFacebookFollowerCount: any;
  setUserFacebookFollowerCount: Dispatch<SetStateAction<any>>;
  userFacebookLikes: any;
  setUserFacebookLikes: Dispatch<SetStateAction<any>>;
  analytics: any;
  setAnalytics: (arg0: any) => void;
  googleSearchKeyWords: Map<string, number>;
  setGoogleSearchKeyWords: Dispatch<SetStateAction<Map<string, number>>>;
  gscImpressions: Map<Date, number>;
  setGscImpressions: Dispatch<SetStateAction<Map<Date, number>>>;
  googleImpressionsCount: number | undefined;
  setGoogleImpressionsCount: Dispatch<SetStateAction<number | undefined>>;
  numberOfWeeks: number;
  setNumberOfWeeks: Dispatch<SetStateAction<number>>;
  downloadAppOnIphoneWithNoHomeButton: boolean;
  setDownloadAppOnIphoneWithNoHomeButton: any;
  requestsCount: any;
  setRequestCount: any;
  userStripeAccountInfoLoading: any;
  allInvoicesForPro: any;
  setAllInvoicesForPro: any;
  reviewRequestModalOpen: boolean;
  setReviewRequestModalOpen: any;
  replyModalOpen: any;
  setReplyModalOpen: any;
  userCampaignInfo: any;
  userCampaignInfoLoading: boolean;
  bookingRequests: any[];
  mutateBookingInfo: typeof mutate;
  userCompleteCampaignInfo: any;
  userCompleteCampaignInfoLoading: boolean;
  showAutoReplyToReviewBanner: boolean;
  setShowAutoReplyToReviewBanner: any;
  competitors: any;
  competitorsLoading: boolean;
  seoData: any;
  seoInfoLoading: boolean;
  gbpSeoLoading: boolean;
  wordsAndPageSeoLoading: boolean;
  proHasChurned: boolean;
  mutateStripeInfo: () => void;
  mutateProfileInfo: () => void;
  mutateBasicInfo: () => void;
  mutateSocialInfo: () => void;
  mutateDigitalMedia: () => void;
  mutateDigitalMediaDefaults: () => void;
  mutatePhotos: () => void;
  mutateGalleries: () => void;
  mutateFacebookPosts: () => void;
  mutateFacebookGroups: () => void;
  mutateGooglePosts: () => void;
  mutateInstagramPosts: () => void;
  mutateScheduledPosts: () => void;
  mutateFeatureUsage: () => void;
  mutateStripeConnectInvoices: () => void;
  mutateYearInReviewVideo: () => void;
  mutateProjectShowcases: () => void;
  userReferrals: IReferral[];
  showQRModal: boolean;
  setShowQRModal: Dispatch<SetStateAction<any>>;
  fetchQuickBookContacts: (id: string) => void;
  fetchWebsiteViews: (slug: string) => any;
  fetchTestimonialsReplied: (id: string) => void;
  allDigitalMedia: any;
  setAllDigitalMedia: Dispatch<SetStateAction<any>>;
  digitalMediaDefaults: any;
  setDigitalMediaDefaults: Dispatch<SetStateAction<any>>;
  physicalMarketingDefaults: any;
  setPhysicalMarketingDefaults: Dispatch<SetStateAction<any>>;
  googleContactsAuthenticated: boolean;
  setGoogleContactsAuthenticated: Dispatch<SetStateAction<boolean>>;
  googleContactsLoading: boolean;
  setGoogleContactsLoading: Dispatch<SetStateAction<boolean>>;
  showContactsTooltip: boolean;
  setShowContactsTooltip: Dispatch<SetStateAction<boolean>>;
  showReviewsTooltip: boolean;
  setShowReviewsTooltip: Dispatch<SetStateAction<boolean>>;
  savedPost: any;
  setSavedPost: Dispatch<SetStateAction<any>>;
  allProsInvoices: any;
  socialConnectionType: string;
  setSocialConnectionType: Dispatch<SetStateAction<string>>;
  showQRReviewDrawer: boolean;
  setShowQRReviewDrawer: (arg0: boolean) => void;
  featureUsage: any;
  setFeatureUsage: Dispatch<SetStateAction<any>>;
  featureLimits: any;
  socialContentError: string | null;
  setSocialContentError: Dispatch<SetStateAction<string | null>>;
  isDigitalAssetAttached: boolean;
  setIsDigitalAssetAttached: Dispatch<SetStateAction<boolean>>;
  attachedPhotos: any[];
  setAttachedPhotos: Dispatch<SetStateAction<any[]>>;
  yearInReviewVideo: {
    video_url: string;
    photos: any[];
    metrics_on: boolean;
  };
  eligibleForMetrics: boolean;
  selectedContacts: IContact[];
  setSelectedContacts: Dispatch<SetStateAction<IContact[]>>;
  contactsToRequestReview: () => IContact[];
  onSiteSEOPillStatus: string;
  setOnSiteSEOPillStatus: Dispatch<SetStateAction<string>>;
  gmbPillStatus: string;
  setGmbPillStatus: Dispatch<SetStateAction<string>>;
  reviewsPillStatus: string;
  setReviewsPillStatus: Dispatch<SetStateAction<string>>;
  postFreqPillStatus: string;
  setPostFreqPillStatus: Dispatch<SetStateAction<string>>;
  chatbotOpen: boolean;
  setChatbotOpen: Dispatch<SetStateAction<boolean>>;
  showChatbotTooltip: boolean;
  setShowChatbotTooltip: Dispatch<SetStateAction<boolean>>;
  showChatbotTooltip2: boolean;
  setShowChatbotTooltip2: Dispatch<SetStateAction<boolean>>;
  generateGroupPost: () => string;
  hasBookingNotifications: boolean;
  isPostHistoryLoading: boolean;
  allProjectShowcases: any[];
  setAllProjectShowcases: Dispatch<SetStateAction<any[]>>;
  calendarEvents: CalendarEvent[];
}

export interface InitialApiLoadingState {
  isDjangoProfileLoading: boolean;
  isStripeInfoLoading: boolean;
  isLandingPageInfoLoading: boolean;
  isBookingRequestInfoLoading: boolean;
  isGmbInfoLoading: boolean;
  isTestimonialInfoLoading: boolean;
  isContactInfoLoading: boolean;
}

export interface PaymentsContextType {
  accountInfo: any;
  setAccountInfo: (arg0: any) => void;
  invoices: any[];
  setInvoices: (arg0: any) => void;
  invoicesFromDb: any[];
  setInvoicesFromDb: (arg0: any) => void;
  quotes: any;
  setQuotes: (arg0: any) => void;
}

export enum AnalyticsType {
  MapPack = "Map Pack",
  Keywords = "Keywords",
}
